<template>
  <b-overlay :show="loadingIndicator" rounded="sm" variant="dark">
    <b-container class="b-card-forms">
      <b-row class="vh-100" align-h="center">
        <b-col align-self="center" cols="12" sm="9" lg="5">
          <b-card>
            <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
            <b-alert
              variant="success"
              :show="emailChangedNotice"
            >{{ $t('login.emailChanged') }}</b-alert>
            <div v-if="!loggedIn && !userNotConfirmed && !passwordChallenge && !mfaChallenge">
              <b-alert
                variant="danger"
                :show="errorOnLoginOccurred"
                v-html="formErrorMsg"
              ></b-alert>
              <b-form @submit.stop.prevent="onSubmit">
                <b-form-group id="email-group">
                  <b-form-input
                    id="email-input"
                    name="email-input"
                    type="email"
                    :placeholder="$t('login.placeholderEmail')"
                    v-model.trim="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="email-live-feedback"
                  ></b-form-input>
                  <b-form-invalid-feedback id="email-live-feedback">
                    {{ $t('login.invalidEmail') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="password-group" class="position-relative">
                  <b-form-input
                    id="password-input"
                    name="password-input"
                    :placeholder="$t('login.placeholderPassword')"
                    :type="showPassword ? 'text' : 'password'"
                    v-model.trim="$v.form.password.$model"
                    :state="validateState('password')"
                    aria-describedby="password-live-feedback"
                  ></b-form-input>
                  <b-icon
                    v-if="!showPassword"
                    icon="eye-slash"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordVisibility(true)"
                  ></b-icon>
                  <b-icon
                    v-else
                    icon="eye"
                    class="position-absolute password-eye"
                    aria-hidden="true"
                    scale="1"
                    @click="togglePasswordVisibility(false)"
                  ></b-icon>
                  <b-form-invalid-feedback id="password-live-feedback">
                    {{ $t('login.requiredPassword') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button
                  block
                  class="p-3"
                  type="submit"
                  variant="success"
                >
                  {{ $t('login.login') }}
                </b-button>
              </b-form>
              <p class="text-center mt-4">
                <small>{{ $t('login.forgotPassword') }}</small>
                <small>
                  <b-link to="/webmag-password-reset">{{ $t('login.resetPassword') }}</b-link>
                </small>
              </p>
              <p class="text-center mt-4">
                <small>{{ $t('login.accountQuestion') }}</small>
                <small>
                  <b-link to="/webmag-signup">{{ $t('login.register') }}</b-link>
                </small>
              </p>
            </div>
            <mfa-challenge
              v-else-if="mfaChallenge"
              :aws-user="awsUser"
              @verification-number-entered="mfaChallengeDone"
            ></mfa-challenge>
            <password-challenge
              v-else-if="passwordChallenge"
              :aws-user="awsUser"
            ></password-challenge>
            <verification-code-msg
              v-else-if="!loadingIndicator"
              :email="form.email"
              @verification-success="verificationNumberEntered"
              @show-loader="showLoader"
            ></verification-code-msg>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-overlay>
</template>

<script>
import { Auth } from '@aws-amplify/auth';
import Login from '@/mixins/login';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';
import SetPageTypeInCache from '@/graphQlQueries/mutations/setPageTypeInCache';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'Login',
  components: {
    VerificationCodeMsg: () => import('@/components/messages/VerificationCode.vue'),
    PasswordChallenge: () => import('@/components/messages/PasswordChallenge.vue'),
    MfaChallenge: () => import('@/components/messages/MfaChallenge.vue'),
  },
  mixins: [Login, validationMixin, SetLanguageToBrowserLanguage],
  data() {
    return {
      awsUser: null,
      loadingIndicator: true,
      loggedIn: false,
      mfaChallenge: false,
      form: {
        email: null,
        password: null,
      },
      errorOnLoginOccurred: false,
      userNotConfirmed: false,
      errorLoginCode: null,
      emailChangedNotice: false,
      showPassword: false,
    };
  },
  computed: {
    formErrorMsg() {
      switch (this.errorLoginCode) {
        case 'NotAuthorizedException':
          return this.$t('login.error.incorrectUserOrPassword');
        default:
          return this.errorLoginCode;
      }
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  },
  created() {
    this.setLanguageToBrowserLanguage();
    this.$apollo.mutate({
      mutation: SetPageTypeInCache,
      variables: {
        type: 'login-page',
      },
    });
    this.$emit('useCrisp', true);
  },
  async mounted() {
    if (this.$route.query?.email) {
      this.form.email = this.$route.query.email;
    }
    if (this.$route.query?.emailChanged) {
      this.emailChangedNotice = true;
    }
    try {
      this.awsUser = await Auth.currentAuthenticatedUser();
      this.loggedIn = !!(this.awsUser);
      await this.checkAwsUser(this.awsUser);
    } catch (error) {
      this.loggedIn = false;
      console.error(error);
    }
    this.loadingIndicator = false;
  },
  methods: {
    async verificationNumberEntered(status) {
      if (status) {
        try {
          await this.awsUserConfirmedAndEmailValidated(this.awsUser);
        } catch (error) {
          console.log(error);
          await this.$router.push('/webmag-logout').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      // logout first so we don't get problems
      await Auth.signOut({ global: true });
      this.errorLoginCode = null;
      this.errorOnLoginOccurred = false;
      this.userNotConfirmed = false;
      await this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      try {
        this.loadingIndicator = true;
        const emailOfUser = this.form.email.toLowerCase().trim();
        // Todo: this is the place where we add the custom user mfa questions!
        const user = await Auth.signIn(emailOfUser, this.form.password);
        this.awsUser = user;
        console.log('user', user);
        if (user?.challengeName === 'SMS_MFA' || user?.challengeName === 'SOFTWARE_TOKEN_MFA') {
          // show the challenge
          console.log('user', user);
          this.mfaChallenge = true;
          this.loadingIndicator = false;
        } else {
          this.loggedIn = !!this.awsUser;
          await this.checkAwsUser(this.awsUser);
          this.loadingIndicator = false;
        }
      } catch (error) {
        this.errorLoginCode = error.code;
        this.errorOnLoginOccurred = true;
        if (error.code === 'UserNotConfirmedException') {
          this.userNotConfirmed = true;
          this.loadingIndicator = false;
          this.loggedIn = false;
        }
        if (error.code === 'NotAuthorizedException') {
          // check if user is disabled
          if (error.message === 'User is disabled.') {
            this.errorLoginCode = 'User is disabled.';
          }
          this.loadingIndicator = false;
          this.loggedIn = false;
        }
        console.log(error);
      }
    },
    mfaChallengeDone(user) {
      this.loadingIndicator = true;
      this.mfaChallenge = false;
      this.loggedIn = !!this.awsUser;
      this.checkAwsUser(user);
    },
    showLoader(value) {
      this.loadingIndicator = !!(value);
    },
    togglePasswordVisibility(value) {
      this.showPassword = !!value;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 35px;
}

/deep/ .disabled {
  cursor: not-allowed;
}

small {
  display: block;
}
.password-eye {
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.is-valid ~ .password-eye,
.is-invalid ~ .password-eye {
  right: 35px;
}
</style>
